import React, { Fragment, useEffect, useState } from 'react';
import Dashboard from './default';
import DashboardCliente from './defaultCliente';
import DefaultClienteCall from './defaultClienteCall';

const Index = () => {
    let a = localStorage.getItem("admin")
    const [Administrator, setAdministrator] = React.useState(a);

    return (
        <div>
            <div className="content">
                <div className="content-wrapper">
                    {
                        Administrator === 'ADMINISTRADOR'
                            ? <Dashboard />
                            : Administrator == 'CALL CENTER CLIENTE'
                                ? <DefaultClienteCall />
                                : <DashboardCliente />
                    }
                </div>
            </div>
        </div>
    );

}

export default Index;