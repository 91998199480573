import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DefaultLayout } from '../../layout/theme-customizer';
import validator from 'validator'

const id = window.location.pathname.split('/').pop()
const defaultLayout = Object.keys(DefaultLayout);
const layout = id ? id : defaultLayout

class Crear extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        tipo_identificacion: '',
        identificacion: '',
        nombre: '',
        estado: '',
        cargo: '',
        password1: '',
        password2: '',
        correo_electronico: '',
        rol: '',
        roles: [],
        cliente: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        console.log(state[e.target.name]);
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    isEmail(email) {
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    }

    validar(pass) {
        if (validator.isStrongPassword(pass, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            
            return 0;
        } else {
            //alert('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscul y un caracter especial!')
            return 1;
        }
    }


    enviarDatos = (e) => {
        e.preventDefault();

        const { tipo_identificacion, identificacion, nombre, estado, cargo, password1, password2, correo_electronico, rol,cliente } = this.state;
        let x = this.validar(password1);

        if (x == 1) {
            alert('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscul y un caracter especial!' + x);
            return false;
        }

        //return false;

        var v = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(correo_electronico)
        if (!v) {
            alert("Digite un email valido");
            return false;
        }


        var errores = [];
        if (!tipo_identificacion) { errores.push("error_tipo_identificacion"); }
        if (!identificacion) { errores.push("error_identificacion"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        if (!cargo) { errores.push("error_usuario"); }
        if (!password1) { errores.push("error_password1"); }
        if (!password2) { errores.push("error_password2"); }
        if (!correo_electronico) { errores.push("error_correo_electronico"); }
        if (!rol) { errores.push("error_rol"); }
        if (!cliente) { errores.push("error_rol"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        if (password1 != password2) {
            alert("Las contraseña no coinciden");
            return false;
        }

        var datosEnviar = {
            tipo_identificacion: tipo_identificacion,
            identificacion: identificacion,
            nombre: nombre,
            estado: estado,
            usuario: cargo,
            password1: password1,
            password2: password2,
            correo_electronico: correo_electronico,
            id_rol: rol,
            cliente: cliente
        }
        authAxios.post("usuario/registrar", datosEnviar)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;


                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'Usuarios!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    window.location.href = `${process.env.PUBLIC_URL}/pages/usuarios/Dubai`;
                    //this.props.history.push("/MasterUsuarios");
                } else {
                    Swal.fire(
                        'Usuarios!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch()
    }
    componentDidMount() {
        Swal.showLoading();
        authAxios.get("usuario/roles")
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;

                this.setState({ datosCargados: true, roles: datosRespuesta.data.data,cliente: datosRespuesta.data.clientes ,load: '' });
                Swal.close();
            })
            .catch();
    }
    render() {
        const { nombre, identificacion, cargo, password1, password2, correo_electronico, roles,cliente } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE USUARIOS</b>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-2">
                                                    <label htmlFor="tipo_identificacion">T. Identificación</label>
                                                    <select name="tipo_identificacion"
                                                        id="tipo_identificacion"
                                                        className={((this.verificarError("error_tipo_identificacion")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el T.I.</option>
                                                        <option value="1">C.C.</option>
                                                        <option value="2">C.E.</option>
                                                        <option value="3">NIT</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el T.I.</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="identificacion">Identificación</label>
                                                    <input className={((this.verificarError("error_identificacion")) ? "is-invalid" : "") + " form-control form-control-sm"} maxLength={20} type="text" name="identificacion" id="identificacion" value={identificacion} placeholder="Numero" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite la Identificación</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre Completo</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="escriba nombre completo" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">Activo</option>
                                                        <option value="1">Inactivo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="rol">Rol</label>
                                                    <select name="rol"
                                                        id="rol"
                                                        className={((this.verificarError("error_rol")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Rol</option>
                                                        {
                                                            roles.map((itemRoles) => (
                                                                <option key={itemRoles.id} value={itemRoles.id}>{itemRoles.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Rol</small>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 0 }} className="row g-3">
                                            <div className="col-md-3">
                                                    <label htmlFor="cliente">Cliente</label>
                                                    <select name="cliente"
                                                        id="cliente"
                                                        className={"form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el cliente</option>
                                                        {
                                                            cliente.map((itemRoles) => (
                                                                <option key={itemRoles.id} value={itemRoles.id}>{itemRoles.nombres} {itemRoles.apellidos}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Rol</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="usuario">Cargo</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_usuario")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="cargo" id="cargo" value={cargo} placeholder="cargo asignado" aria-describedby="helpId" onChange={this.cambioValor} autoComplete="off" />
                                                    <small id="helpId" className="invalid-feedback">Digite el Cargo</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password1">Password</label>
                                                    <input className={((this.verificarError("error_password1")) ? "is-invalid" : "") + " form-control form-control-sm"} type="password" name="password1" id="password1" value={password1} placeholder="PASSWORD" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password2">Confirmar Password</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_password2")) ? "is-invalid" : "") + " form-control form-control-sm"} type="password" name="password2" id="password2" value={password2} placeholder="CONFIRMAR PASS." aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="correo_electronico">Correo Electrónico</label>
                                                    <input style={{ textTransform: 'lowercase' }} className={((this.verificarError("error_correo_electronico")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="correo_electronico" id="correo_electronico" value={correo_electronico} placeholder="ejemplo@dominio.com" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Crear Usuario</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/pages/usuarios/${layout}`}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;