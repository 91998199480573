import React from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import $ from "jquery";
import "select2/dist/js/select2.min.js";
import "select2/dist/css/select2.min.css";
import { TimePicker } from 'rsuite';
import 'rsuite/TimePicker/styles/index.css';
import './index.css';

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      ListaTabla: [],
      ListaEstados: [],
      ListaCliente: [],
      ListaCiudades: [],
      ListaCiudadesFiltradas: [],
      ListaDepartamentos: [],
      ListaAseguradoras: [],
      ListaPrefactura: [],
      ListaEstadosSiniestros: [],
      Hoy: hoy,
      seleccionarTodo: false,
      idsSeleccionados: [],
      clientesConBoton: [],
      idClienteInfo: [],
      modalEstadoVisible: false,
      selectedEstado: "",
      estados: [],
      modalInfoVisible: false,
      modalInfoVisibleEditar: false,
      subtotal: 0,
      Pilotos: [],
      filteredPilotos: [],
      serviciospendientes: [],
      HorasConductor: [],
      Dias: [],
      modalVisible: false,
      selectedItem: null, // Item seleccionado para cambiar conductor
      conductores: [], // Lista de conductores
      selectedConductor: "",
      tooltip: {
        visible: false,
        x: 0,
        y: 0,
        item: null,
      },
      showModalServs: false,
      serviciosHora: [],
      horaSeleccionada: "",
      filtrocat: "",
      filtrocond: "",
      filtroplaca: "",
      filtrotip: "",
      porasignar: false,
      porasignarServ: false,
      categorias: {
        PRIMEROS: [],
        SEGUNDOS: [],
        TERCEROS: [],
        AFILIADOS: [],
        ELEGIBLES: [],
        OTROS: [],
      },
      categoriasAgrupadas: {},
      listaConductores: [],
      listaVhs: [],
      listaTipoVh: [],
      Usuario: localStorage.getItem('Name')
    }
  }
  abrirModal = (hora) => {
    const serviciosFiltrados = (this.state.serviciospendientes).filter((p) => {
      console.log("Servicio hora_inicial:", p.hora_inicial); // Muestra la hora de cada servicio
      return p.hora_inicial && p.hora_inicial.split(":")[0] == hora;
    });
    console.log(serviciosFiltrados);
    this.setState({
      serviciosHora: serviciosFiltrados,
      horaSeleccionada: hora,
      showModalServs: true,
    });
  };
  handleChange = (field, value) => {
    // Si el valor es un evento estándar de HTML, extrae el valor de `target.value`
    if (value && value.target) {
      value = value.target.value;
    }

    // Si el campo es "hora_inicial", formatea el valor a una cadena de tiempo
    if (field === "hora_inicial") {
      value = this.formatDateToTimeString(value);
    }

    // Actualiza el estado
    this.setState((prevState) => ({
      selectedItem: {
        ...prevState.selectedItem,
        [field]: value,
      },
    }));
  };
  parseTimeStringToDate = (timeString) => {
    console.log("intento pasrsear" + timeString);
    if (!timeString) return null; // Maneja casos donde el valor es nulo o indefinido
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  };
  formatDateToTimeString = (date) => {
    if (!date) return ""; // Maneja casos donde el valor es nulo o indefinido
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}:00`; // Formato HH:mm:ss
  };

  cerrarModal = () => {
    this.setState({ showModalServs: false });
  };
  porServicioTocar = (id) => {
    this.setState({ showModalServs: false, porasignar: true, porasignarServ: id });

  };
  noHayServicio = (id) => {
    this.setState({ porasignar: false, porasignarServ: '' });

  };
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  openModalInfo = (item) => {
    this.closeTooltip();
    this.setState({
      modalInfoVisible: true,
      selectedItem: this.state.tooltip.item,
    });
  };

  openModalInfoEditar = (item) => {
    this.closeTooltip();
    this.setState({
      modalInfoVisibleEditar: true,
      selectedItem: this.state.tooltip.item,
    });
  };
  closeModalInfoEditar = () => {
    this.setState({ modalInfoVisibleEditar: false });
  };
  closeModalInfo = () => {
    this.setState({ modalInfoVisible: false });
  };
  openModalEstado = (item) => {
    this.closeTooltip();
    console.log(this.state.tooltip.item);
    this.setState({
      modalEstadoVisible: true,
      selectedItem: this.state.tooltip.item,
      selectedEstado: this.state.tooltip.item.estado_servicio, // Selecciona el estado actual
    });
  };

  closeModalEstado = () => {
    this.setState({ modalEstadoVisible: false });
  };
  handleRowClick = (event, item) => {
    console.log(item);
    const rect = event.target.getBoundingClientRect();
    this.setState({
      tooltip: {
        visible: true,
        x: rect.x - 500,
        y: rect.y + window.scrollY - 60,
        item,
      },
    });
  };
  actualizarConductor(id) {
    Swal.showLoading();
    const data = {
      conductor: this.state.selectedConductor
    };
    authAxios.post("exterior/actualizar-conductor/" + id, data)
      .then((datosRespuesta) => {
        Swal.close();
        var m = datosRespuesta.data.msj;
        this.MostrarTabla();
        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  actualizarEstado(id) {
    Swal.showLoading();
    const { selectedEstado } = this.state;
    const data = {
      estado: selectedEstado
    };
    authAxios.post("exterior/actualizar-estado/" + id, data)
      .then((datosRespuesta) => {

        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          this.MostrarTabla();
          if (result.isConfirmed) {
            this.MostrarTabla();
            Swal.close();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  guardarEdicion = (id) => {
    Swal.showLoading();
    const { selectedItem } = this.state;
    const data = {
      orden_servicio_new: selectedItem.orden_servicio_new,
      estado_nombre: selectedItem.estado_nombre,
      numero_contacto: selectedItem.numero_contacto,
      fecha: selectedItem.fecha,
      hora_inicial: selectedItem.hora_inicial,
      placa_vh: selectedItem.placa_vh,
      direccion_inicial: selectedItem.direccion_inicial,
      direccion_final: selectedItem.direccion_final,
      observaciones: selectedItem.observaciones,
      movil_conductor: selectedItem.movil_conductor,
    };

    authAxios.post("exterior/actualizar-datos/" + id, data)
      .then((response) => {
        Swal.fire({
          title: response.data.msj,
          text: 'Actualización exitosa',
          icon: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          this.MostrarTabla();
          if (result.isConfirmed) {
            this.MostrarTabla();
            Swal.close();
          }
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: 'Error',
          text: 'No se pudo actualizar la información',
          icon: 'error',
          showConfirmButton: true,
        });
      });
  };

  // Método para cerrar el tooltip
  closeTooltip = () => {
    this.setState({
      tooltip: { visible: false, x: 0, y: 0, item: null },
    });
  };
  openModal = (item) => {
    this.closeTooltip();
    console.log(this.state.tooltip.item);
    this.setState({
      modalVisible: true,
      selectedItem: this.state.tooltip.item,
      // Conductor actual
    });
  };
  // Agrega un manejador de eventos para el cambio en el campo de selección de departamento
  handleDepartamentoChange = (event) => {
    const departamentoSeleccionado = event.target.value;

    // Filtra la lista de ciudades en función del departamento seleccionado
    const ciudadesFiltradas = this.state.ListaCiudades.filter(
      (ciudad) => ciudad.departamento === departamentoSeleccionado
    );

    this.setState({
      ListaCiudadesFiltradas: ciudadesFiltradas,
    });
  };
  exportToExcel = () => {
    let idx = document.getElementById('idprefactura').value;
    const { ListaPrefactura } = this.state;

    const ws = XLSX.utils.json_to_sheet(ListaPrefactura);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

    XLSX.writeFile(wb, 'Prefactura' + idx + '.xlsx');
  };


  consultarClienteId(identificacion) {
    console.log(this.state.clientesConBoton)
    Swal.showLoading();
    authAxios.post("exterior/consulta-id-cliente/" + identificacion)
      .then((datosRespuesta) => {
        Swal.close();
        var respuesta = datosRespuesta.data.datos[0];
        console.log(datosRespuesta.data);
        // document.getElementById("tipoDocumento").value = respuesta.tipoDocumento;
        document.getElementById("nombres").value = respuesta.nombre;
        document.getElementById("telefono").value = respuesta.telefono;
        // document.getElementById("departamento").value = respuesta.departamento;
        document.getElementById("documento").value = respuesta.identificacion;
        document.getElementById("email").value = respuesta.email;
        document.getElementById("apellidos").value = respuesta.apellido;
        // document.getElementById("ciudad").value = respuesta.ciudad;
        document.getElementById("direccion").value = respuesta.direccion;

        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }


  GenerarSiigo(id) {
    Swal.showLoading();
    authAxios.post("exterior/exterior-siigo/" + id)
      .then((datosRespuesta) => {
        Swal.close();
        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  guardarCliente = (e) => {

    e.preventDefault();
    Swal.showLoading();
    // Recopila los datos del formulario en un objeto data
    const data = {
      tipoDocumento: document.getElementById('tipoDocumento').value,
      nombres: document.getElementById('nombres').value,
      telefono: document.getElementById('telefono').value,
      departamento: document.getElementById('departamento').value,
      documento: document.getElementById('documento').value,
      email: document.getElementById('email').value,
      apellidos: document.getElementById('apellidos').value,
      ciudad: document.getElementById('ciudad').value,
      direccion: document.getElementById('direccion').value,
    };
    this.setState({ clientesConBoton: [] }, () => {
      // Código que depende del estado actualizado
      // Realiza la solicitud a la API con los datos del formulario
      authAxios.post('exterior/guardar-cliente', data)
        .then(response => {
          Swal.close();
          console.log(this.state.clientesConBoton)
          this.MostrarTabla(1);
          // Maneja la respuesta de la API si es necesario
          console.log(response.data);
          const miBoton = document.getElementById('miBoton');

          // Simula un clic en el botón
          miBoton.click();
        })
        .catch(error => {
          // Maneja los errores si la solicitud falla
          console.error(error);
        });
    });

  }
  componentWillUnmount() {
    clearInterval(this.intervalId); // Limpiar el intervalo cuando el componente se desmonta
  }
  MostrarTabla() {
    this.setState({ load: true, clientesConBoton: [] });
    // Swal.showLoading();
    let bfecha = document.getElementById('bfecha').value;

    const { filtrotip, filtroplaca, filtrocond, filtrocat } = this.state;

    const formatSelectedValues = (values) => {
      if (Array.isArray(values)) {
        return values.join(','); // Une los valores con comas si es un array
      }
      return values || ''; // Devuelve el valor único o una cadena vacía si es undefined
    };

    // Formatear los valores seleccionados
    const vtipovhFormatted = formatSelectedValues(filtrotip);
    const bvehiculoFormatted = formatSelectedValues(filtroplaca);
    const bconductorFormatted = formatSelectedValues(filtrocond);
    const bcategoriaFormatted = formatSelectedValues(filtrocat);
    const datos = {
      fechai: bfecha,
      vtipovh: vtipovhFormatted,
      bvehiculo: bvehiculoFormatted,
      bconductor: bconductorFormatted,
      bcategoria: bcategoriaFormatted,
    };
    this.setState({ clientesConBoton: [] }, () => {
      authAxios.post("exterior/consultaservicio-tiemporeal", datos)
        .then((datosRespuesta) => {
          Swal.close();
          var status = datosRespuesta.data.status;
          const categoriasMap = new Map([
            ["1", "PRIMEROS"],
            ["2", "SEGUNDOS"],
            ["3", "TERCEROS"],
            ["4", "AFILIADOS"],
            ["5", "ELEGIBLES"]
          ]);


          const categoriasAgrupadas = {};
          datosRespuesta.data.pilotos.forEach(piloto => {
            const categoriaNombre = categoriasMap.get(piloto.id_categoria) || "zSIN CATEGORIA";
            if (!categoriasAgrupadas[categoriaNombre]) categoriasAgrupadas[categoriaNombre] = [];
            categoriasAgrupadas[categoriaNombre].push(piloto);
          });



          console.log('aqui es');
          console.log(categoriasAgrupadas);
          Swal.close();
          this.setState({ datosCargados: true, serviciospendientes: datosRespuesta.data.servicios_pendiente, categoriasAgrupadas, load: '', Pilotos: datosRespuesta.data.pilotos, filteredPilotos: datosRespuesta.data.pilotos, Dias: datosRespuesta.data.horas, HorasConductor: datosRespuesta.data.pilotos, load: '', ListaTabla: datosRespuesta.data.datos, clientesConBoton: [] });
        })
        .catch(() => {
          this.setState({ clientesConBoton: [] });
        });
    })
    //window.location.href = '/'; window.localStorage.clear();
  }

  handleSelectConductor = (selectedOption) => {
    console.log("Selected:", selectedOption);
    this.setState({ selectedConductor: selectedOption });
  };


  MostrarClientes() {

    authAxios.get("exterior/consultacliente")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data.conductores);
        this.setState({
          datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras, ListaCiudades: datosRespuesta.data.ciudades,
          ListaDepartamentos: datosRespuesta.data.departamentos,
          ListaEstadosSiniestros: datosRespuesta.data.estados_siniestro,
          estados: datosRespuesta.data.estados_siniestro,
          listaConductores: datosRespuesta.data.conductores,
          listaVhs: datosRespuesta.data.vehiculos,
          listaTipoVh: datosRespuesta.data.tipo_vh,
          conductores: datosRespuesta.data.conductores
        });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  Enviar = () => {
    let id = document.getElementById('idprefactura').value;
    var dati = { id: id }
    authAxios.post("exterior/consultarprefactura", dati)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data.datos);
        this.setState({ ListaPrefactura: datosRespuesta.data.datos });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  UpValores(id) {
    const { idsSeleccionados } = this.state;
    let valor = document.getElementById('valor' + id).value;
    var datos = { id: id, valor: valor }
    console.log(idsSeleccionados);
    authAxios.post("exterior/actualizavalor", datos)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;
        this.setState((prevState) => {
          const updatedListaTabla = prevState.ListaTabla.map(item => {
            if (item.id === id) {
              return { ...item, valor_servicio: valor };
            }
            return item;
          });
          return { ListaTabla: updatedListaTabla };
        }, this.calcularSubtotal);

      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  GuardarValores = () => {
    Swal.fire({
      title: 'Está seguro de Generar la prefactura?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Generar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        let origen = document.getElementById('borigen').value;
        const { idsSeleccionados, Usuario } = this.state;
        if (idsSeleccionados.length === 0) {
          Swal.fire(
            'Debes de seleccionar los servicios',
            '',
            'warning'
          )
          return false;
        }

        var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
        console.log(idsSeleccionados);
        authAxios.post("exterior/guardarseleccionado", datos)
          .then((datosRespuesta) => {
            var m = datosRespuesta.data.msj;
            var status = datosRespuesta.data.status;
            document.getElementById('bfecha').value = '';
            document.getElementById('bfecha2').value = '';
            document.getElementById('borigen').value = '';
            document.getElementById('bestado').value = '';
            document.getElementById('ase').value = '';
            document.getElementById('exp').value = '';
            this.setState({
              idsSeleccionados: [],
              subtotal: 0,
            });
            Swal.fire({
              title: m,
              text: 'Facturado',
              icon: 'success',
              showConfirmButton: true, // Mostrar el botón "Confirmar"
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.MostrarTabla();
              }
            });
          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }
  AnularValores = () => {
    Swal.fire({
      title: 'Está seguro de Anular los servicios seleccionados?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Generar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let origen = document.getElementById('borigen').value;
        const { idsSeleccionados, Usuario } = this.state;
        if (idsSeleccionados.length === 0) {
          Swal.fire(
            'Debes de seleccionar los servicios',
            '',
            'warning'
          )
          return false;
        }

        var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
        console.log(idsSeleccionados);
        authAxios.post("exterior/anularseleccionado", datos)
          .then((datosRespuesta) => {
            var m = datosRespuesta.data.msj;
            var status = datosRespuesta.data.status;
            Swal.fire({
              title: m,
              text: 'Facturado',
              icon: 'success',
              showConfirmButton: true, // Mostrar el botón "Confirmar"
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.MostrarTabla();
              }
            });

          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }
  handlePrint = () => {
    // Obtén el contenido del div que deseas imprimir
    const printContent = document.getElementById("printable-content").innerHTML;

    // Crea una ventana nueva
    const printWindow = window.open("", "_blank");

    // Escribe el contenido en la nueva ventana
    printWindow.document.write(`
      <html>
        <head>
          <title>Imprimir ticket</title>
          <style>
          @media print {
            .no-print {
              display: none; /* Oculta elementos con la clase no-print */
            }
          }
            /* Estilos para la impresión */
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Fondo degradado */
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              
            }
            .logo {
              text-align: center;
              margin-bottom: 20px;
            }
            .logo img {
              max-width: 150px; /* Ajusta el tamaño del logo */
              height: auto;
            }
            h5, h6 {
              color: blue;
              text-align: center;
              font-size: 18px;
              margin:0;
            }
            .row {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
            }
            .col-md-6 {
              width: 50%;
            }
            strong {
              font-weight: bold;
            }
            .ticket-content {
              background: #fff; /* Fondo blanco para el contenido */
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              width: 100%;
              max-width: 90%;
              font-size: 14px;
            }
          </style>
        </head>
        <body>
          
          <div class="ticket-content">
          <div class="logo">
            <img src="https://app.transorientesas.com/storage/transoriente/clientes/wfXwWyIwicU2RSsRYKQo7W50knKYCwYY7T8ehdwa.png" alt="Logo de la empresa" /> <!-- Ruta del logo -->
          </div>
            ${printContent}
          </div>
        </body>
      </html>
    `);

    // Cierra el documento para que se cargue el contenido
    printWindow.document.close();

    // Abre el diálogo de impresión
    printWindow.print();
  };
  handleSelectAll = () => {
    this.setState((prevState) => {
      const { ListaTabla, seleccionarTodo } = prevState;
      const nuevosIdsSeleccionados = !seleccionarTodo ? ListaTabla.map((item) => item.id) : [];
      return {
        seleccionarTodo: !seleccionarTodo,
        idsSeleccionados: nuevosIdsSeleccionados,
      };
    }, this.calcularSubtotal);
  };
  formatDate(dateString) {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    console.log(dateString);
    const date = new Date(dateString + 'T00:00:00'); 
    console.log(date);
    const dayOfWeek = days[date.getDay()]; // Obtiene el día de la semana (Lunes, Martes, etc.)
    console.log(dayOfWeek);
    const dayOfMonth = date.getDate(); // Obtiene el día del mes (1, 2, 3, ..., 31)
    console.log(dayOfMonth);
    const month = months[date.getMonth()]; // Obtiene el nombre del mes (enero, febrero, etc.)
    const year = date.getFullYear(); // Obtiene el año (2025)

    return `${dayOfWeek}, ${dayOfMonth} de ${month} de ${year}`;
  }

  handleCheckboxChange = (id) => {
    this.setState((prevState) => {
      const { idsSeleccionados } = prevState;
      const updatedIds = idsSeleccionados.includes(id) ? idsSeleccionados.filter(itemId => itemId !== id) : [...idsSeleccionados, id];
      return { idsSeleccionados: updatedIds };
    }, this.calcularSubtotal);
  };
  calcularSubtotal = () => {
    const { ListaTabla, idsSeleccionados } = this.state;
    const subtotal = ListaTabla.reduce((total, item) => {
      console.log(item);
      return idsSeleccionados.includes(item.id) ? total + parseFloat(item.facturado) : total;
    }, 0);
    this.setState({ subtotal });
  }

  componentDidMount() {
    const categoriaMap = {
      1: "PRIMEROS",
      2: "SEGUNDOS",
      3: "TERCEROS",
      4: "AFILIADOS",
      5: "ELEGIBLES",
    };
    this.intervalId = setInterval(() => {
      this.MostrarTabla();
    }, 15000); // 15 segundos
    this.MostrarClientes(1);
    $("#selectConductor").select2({
      placeholder: "Seleccione un conductor",
      allowClear: true,
      multiple: true,
      width: "100%",
    });
    $("#borigen").select2({
      placeholder: "Seleccione un cliente",
      allowClear: true,
      width: "100%",
    });
    $("#vtipovh").select2({
      placeholder: "Tipo Vehiculo",
      allowClear: true,
      width: "100%",
      multiple: true,
    });
    $("#bvehiculo").select2({
      placeholder: "Placa",
      allowClear: true,
      width: "100%",
      multiple: true,
    });
    $("#bconductor").select2({
      placeholder: "Conductor",
      allowClear: true,
      width: "100%",
      multiple: true,
    });
    $("#bcategoria").select2({
      placeholder: "Categoria",
      allowClear: true,
      width: "100%",
      multiple: true,
    });

    $("#bcategoria").on("change", () => {
      const valoresSeleccionados = $("#bcategoria").val();
      console.log("Valores seleccionados (categoría):", valoresSeleccionados);
      this.setState({ filtrocat: valoresSeleccionados });
    });

    $("#vtipovh").on("change", () => {
      const valoresSeleccionados = $("#vtipovh").val();
      console.log("Valores seleccionados (tipo vehículo):", valoresSeleccionados);
      this.setState({ filtrotip: valoresSeleccionados });
    });

    $("#bvehiculo").on("change", () => {
      const valoresSeleccionados = $("#bvehiculo").val();
      console.log("Valores seleccionados (placa):", valoresSeleccionados);
      this.setState({ filtroplaca: valoresSeleccionados });
    });

    $("#bconductor").on("change", () => {
      const valoresSeleccionados = $("#bconductor").val();
      console.log("Valores seleccionados (conductor):", valoresSeleccionados);
      this.setState({ filtrocond: valoresSeleccionados });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.modalVisible && !prevState.modalVisible) {
      // Reaplica Select2 si el modal se vuelve visible
      $("#selectConductor").select2({
        placeholder: "Seleccione un conductor",
        allowClear: true,
        width: "100%",
      });
      $("#borigen").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
        width: "100%",
      });
      $("#vtipovh").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
        width: "100%",
      });
      $("#bvehiculo").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
        width: "100%",
      });
      $("#bconductor").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
        width: "100%",
      });
      $("#bcategoria").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
        width: "100%",
      });
      $("#selectConductor").on("change", (e) => {
        console.log("log");
        this.setState({ selectedConductor: e.target.value });
      });
    }
  }
  render() {
    const { load, ListaCiudadesFiltradas, listaConductores, listaVhs, listaTipoVh, serviciospendientes, showModalServs, horaSeleccionada, serviciosHora, categorias, categoriasAgrupadas, ListaTabla, Dias, Pilotos, ListaEstadosSiniestros, subtotal, pagina, ultimo, ListaPrefactura, actual, Hoy, ListaAseguradoras, ListaEstados, ListaCliente, seleccionarTodo, idsSeleccionados, ListaDepartamentos, ListaCiudades, tooltip } = this.state;
    return (
      <div className="content">
        <div className="container-fluid">
          <br />
          <div className="card">

            <div className="card-body">

              <h4>Lista Conductores En tiempo Real</h4>
              <hr />
              <div className="row">
                <div className="col-xl-2">
                  <select id="bcategoria" name="bcategoria" className="form-control form-control-sm" multiple >

                    <option value="1">1 - PRIMEROS</option>
                    <option value="2">2 - SEGUNDOS</option>
                    <option value="3">3 - TERCEROS</option>
                    <option value="4">4 - AFILIADOS</option>
                    <option value="5">5 - ELEGIBLES</option>
                  </select>
                </div>
                <div className="col-xl-2">
                  <select id="bconductor" name="bconductor" className="form-control form-control-sm" multiple >

                    {
                      listaConductores
                        .map((item, i) => (
                          <option key={i} value={item.id}>{item.nombre}</option>
                        ))
                    }
                  </select>
                </div>
                <div className="col-xl-2">
                  <select id="bvehiculo" name="bvehiculo" className="form-control form-control-sm" multiple >

                    {
                      listaVhs
                        .map((item, i) => (
                          <option key={i} value={item.id}>{item.placa}</option>
                        ))
                    }
                  </select>
                </div>
                <div className="col-xl-2">
                  <select id="vtipovh" name="vtipovh" className="form-control form-control-sm" multiple >

                    {
                      listaTipoVh.map((ite, i) => (
                        <option key={ite.id} value={ite.id}>{ite.nombre}</option>
                      ))}
                  </select>
                </div>




                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                </div>

                <div className="col-xl-2">
                  <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                    padding: "10px",
                  }}
                >
                  {ListaEstadosSiniestros.map((estado) => (
                    <div
                      key={estado.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: estado.color,
                          marginRight: "8px",
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                        }}
                      ></div>
                      <span>{estado.estado}</span>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  padding: "10px",
                }}
                >
                {ListaEstadosSiniestros.map((estado) => (
                  <div
                    key={estado.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: estado.color,
                        marginRight: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <span>{estado.estado}</span>
                  </div>
                ))}
              </div> */}
              {this.state.load ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Cargando...</span>
                  </div>
                </div>
              ) : ''}
              <br /><br />
              <div className="row " >


                <div className="col-md-12 table-responsive" style={{
                  overflowX: "scroll",
                  overflowY: "hidden",
                  transform: "rotateX(180deg)",

                }}>
                  <div style={{
                    transform: "rotateX(180deg)", // Revertir la rotación para el contenido
                  }}>

                    {/* <tbody> */}
                    {load}
                    {Object.keys(categoriasAgrupadas)
                      .sort((a, b) => a.localeCompare(b)) // Esto ordena alfabéticamente
                      .map((categoria, index) => (
                        categoriasAgrupadas[categoria].length > 0 && (
                          <div key={index}>
                            <h3>{categoria}</h3>
                            <table className="table-bordered">
                              <thead className="bg-secondary">


                                <tr>
                                  {/* <th className="align-middle">ITEM</th> */}
                                  <th className="text-justify" style={{ width: "220px" }} >CONDUCTOR</th>
                                  <th className="align-middle" >VEHICULO</th>
                                  <th className="text-justify" style={{ width: "160px" }}>TIPO VH</th>

                                  {
                                    Dias.map((item, g) => (

                                      <th key={g}
                                        className={`align-middle ${serviciospendientes.some(
                                          (p) => p.hora_inicial.split(":")[0] === item
                                        )
                                          ? "bg-danger text-white"
                                          : ""
                                          }`}
                                        onClick={() => this.abrirModal(item)}
                                        style={{ cursor: "pointer" }} >{item}</th>
                                    ))
                                  }


                                </tr>
                              </thead>
                              <tbody>
                                {categoriasAgrupadas[categoria].map((row, i) => (
                                  <tr key={i}>
                                    <td className="text-justify">{row.nombre} {row.apellidos}</td>
                                    <td className="text-justify">{row.placa}</td>
                                    <td className="text-justify">{row.tipo}</td>
                                    {Dias.map((it, h) => (
                                      <td key={h}>
                                        <table className="align-middle">
                                          <tbody>
                                            <tr>
                                              {[0, 15, 30, 45].map(minuto => (
                                                <td
                                                  key={minuto}
                                                  style={{
                                                    backgroundColor:
                                                      row.pintar_hora[h].seleccionar === 'success'
                                                        ? row.pintar_hora[h].minuto <= minuto
                                                          ? row.pintar_hora[h].color_estado
                                                          : 'lightgray'
                                                        : row.pintar_hora[h].seleccionar_fin === 'success'
                                                          ? row.pintar_hora[h].minuto > minuto
                                                            ? row.pintar_hora[h].color_estado
                                                            : 'lightgray'
                                                          : 'lightgray',
                                                    border: '1px solid black'
                                                  }}
                                                  onClick={(e) => this.handleRowClick(e, row.pintar_hora[h].servicio)}
                                                  title={`${it}:${minuto}`}
                                                >
                                                  {this.state.horaSeleccionada === it && this.state.porasignar == true ? (
                                                    <span style={{ color: 'blue', fontSize: '24px' }}>&#x25CF;</span>
                                                  ) : (
                                                    '..'
                                                  )}
                                                </td>
                                              ))}
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )
                      ))}
                    {/* </tbody> */}
                    {/* </table> */}
                  </div>
                </div>
                {
                  this.state.showModalServs && (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

                        padding: "20px",
                        width: "400px",
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title">Servicios de la Hora {horaSeleccionada}</h5>
                        <button type="button" className="btn-close" onClick={this.cerrarModal}></button>
                      </div>
                      <div className="modal-body">
                        {serviciosHora.length > 0 ? (
                          <>
                            <hr />
                            <ul>
                              {serviciosHora.map((servicio, index) => (
                                <li key={index}>
                                  <strong>Servicio:</strong> {servicio.orden_servicio_new} |{" "}
                                  <strong>Numero Contacto:</strong> {servicio.numero_contacto} |{" "}
                                  <strong>Fecha Hora:</strong> {servicio.fecha + ' ' + servicio.hora_inicial} |{" "}
                                  <strong>Direccion:</strong> {servicio.direccion_inicial} <button className='btn btn-success' onClick={() => this.porServicioTocar(servicio.id)}> Elegir</button>
                                </li>
                              ))}
                            </ul>
                            <hr />
                          </>
                        ) : (
                          <p onClick={this.noHayServicio()}>No hay servicios en esta hora.</p>

                        )}
                      </div>
                      <div className="modal-footer">
                        <button className="btn btn-secondary" onClick={this.cerrarModal}>
                          Cerrar
                        </button>
                      </div>
                    </div>
                  )
                }

                {tooltip.visible && (
                  <div
                    style={{
                      position: "absolute",
                      top: tooltip.y,
                      left: tooltip.x,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                      padding: "10px",
                      width: "500px"
                    }}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.openModalEstado(tooltip.selectedItem)}
                    >
                      Cambiar Estado
                    </button>
                    <button
                      className="btn btn-warning btn-sm mx-2"
                      onClick={() => this.openModal(tooltip.selectedItem)}
                    >
                      Cambiar Conductor
                    </button>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => this.openModalInfo(tooltip.selectedItem)}
                    >
                      Ver Información
                    </button>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => this.openModalInfoEditar(tooltip.selectedItem)}
                    >
                      Editar Información
                    </button>
                    <button
                      className="btn btn-danger btn-sm mt-2"
                      onClick={this.closeTooltip}
                      style={{ display: "block", marginTop: "10px" }}
                    >
                      Cerrar
                    </button>
                  </div>
                )}
              </div>

            </div>
            <div className="card-footer text-muted">
              <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
            </div>
          </div>
        </div>
        {
          this.state.modalVisible && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

                padding: "20px",
                width: "400px",
              }}
            >
              <h5>Cambiar Conductor - Servicio {this.state.selectedItem.orden_servicio_new}</h5>
              <p><strong>Conductor Actual:</strong> {this.state.selectedItem.nombre_conductor}</p>
              <div className="form-group">
                <label htmlFor="selectConductor">Seleccionar Nuevo Conductor:</label>
                <select
                  id="selectConductor"
                  className="form-control"
                  style={{ zIndex: 9999 }}
                  value={this.state.selectedConductor}
                  onChange={this.handleSelectConductor}
                  placeholder="Seleccione un conductor"
                >
                  {this.state.conductores.map((conductor, index) => (
                    <option key={index} value={conductor.identificacion}>
                      {conductor.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  console.log("Nuevo Conductor:", this.state.selectedConductor);
                  this.actualizarConductor(this.state.selectedItem.id);
                  this.closeModal();

                }}
              >
                Guardar
              </button>
              <button
                className="btn btn-secondary mt-3 ml-2"
                onClick={this.closeModal}
              >
                Cancelar
              </button>
            </div>
          )
        }
        {
          this.state.modalEstadoVisible && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                padding: "20px",
                width: "400px",
              }}
            >
              <h5>Cambiar Estado - Servicio {this.state.selectedItem?.orden_servicio_new}</h5>
              <p>
                <strong>Estado Actual:</strong>{" "}
                {this.state.selectedItem?.estado_nombre}
              </p>
              <div className="form-group">
                <label htmlFor="selectEstado">Seleccionar Nuevo Estado:</label>
                <select
                  id="selectEstado"
                  className="form-control"
                  style={{ zIndex: 9999 }}
                  value={this.state.selectedEstado}
                  onChange={(e) =>
                    this.setState({ selectedEstado: e.target.value })
                  }
                >
                  {this.state.estados.map((estado, index) => (
                    <option key={index} value={estado.id}>
                      {estado.estado}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  console.log("Nuevo Estado:", this.state.selectedEstado);
                  this.actualizarEstado(this.state.selectedItem.id);
                  this.closeModalEstado();
                }}
              >
                Guardar
              </button>
              <button
                className="btn btn-secondary mt-3 ml-2"
                onClick={this.closeModalEstado}
              >
                Cancelar
              </button>
            </div>
          )
        }
        {
          this.state.modalInfoVisible && (
            <div
              id="printable-content"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                padding: "20px",
                maxHeight: "80vh", // Ajusta este valor según tus necesidades
                overflowY: "auto", // Permite el desplazamiento vertical si el contenido es demasiado largo
                zIndex: "99999",
                width: "90%", // Ajusta el ancho si es necesario
                maxWidth: "600px", // Ajusta el ancho máximo si es necesario
              }}
            >
              <h4 className="mb-4">Información - {this.state.selectedItem?.nombre_clientea}</h4>

              <div>
                <h6 className="text-primary">General</h6>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>Expediente:</strong> {this.state.selectedItem?.orden_servicio_new}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Estado:</strong> {this.state.selectedItem?.estado_nombre}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Teléfono:</strong> {this.state.selectedItem?.numero_contacto}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong style={{color:'red'}}>Fecha: {this.formatDate(this.state.selectedItem?.fecha)}</strong> 
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Celular Responsable Servicio:</strong> {this.state.selectedItem?.numero_contacto}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong style={{color:'red'}}>Hora: {this.state.selectedItem?.hora_inicial} <br /> Hora Militar</strong> 
                    </p>
                  </div>
                </div>

                <div className="row">
                  
                  <div className="col-md-6">
                    <p>
                      <strong>Nombre Responsable Servicio:</strong> {this.state.selectedItem?.nombre_logistica}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong style={{color:'red'}}>Origen: {this.state.selectedItem?.direccion_inicial}</strong> 
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong style={{color:'red'}}>Destino: {this.state.selectedItem?.direccion_final}</strong> 
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      <strong>Observaciones:</strong> {this.state.selectedItem?.observaciones}
                    </p>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              <div>
                <h6 className="text-primary">Usuario</h6>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>Nombre y Apellido:</strong> {this.state.selectedItem?.nombre_cliente}
                    </p>
                  </div>
                  {/* </div> */}
                  {/* <div className="row"> */}
                  <div className="col-md-6">
                    <p>
                      <strong>Identificación:</strong>{" "}
                      {this.state.selectedItem?.identificacion_usuario_name}
                    </p>
                  </div>
                  {/* </div> */}
                  {/* <div className="row"> */}
                  <div className="col-md-6">
                    <p>
                      <strong>Telefono:</strong> {this.state.selectedItem?.numero_contacto}
                    </p>
                  </div>
                  {/* </div> */}
                  {/* <div className="row"> */}
                  <div className="col-md-6">
                    <p>
                      <strong>Direccion:</strong> {this.state.selectedItem?.direccion_usuario}
                    </p>
                  </div>
                  {/* </div> */}
                  {/* <div className="row"> */}
                  <div className="col-md-6">
                    <p>
                      <strong>Correo:</strong> {this.state.selectedItem?.email_cliente}
                    </p>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              <div>
                <h6 className="text-primary">Conductor</h6>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>Nombre:</strong> {this.state.selectedItem?.nombre_conductor}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Identificación:</strong>{" "}
                      {this.state.selectedItem?.conductor}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Telefono:</strong> {this.state.selectedItem?.movil_conductor}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Placa:</strong> {this.state.selectedItem?.placa_vh}
                    </p>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-secondary mt-3 mr-3 no-print"
                onClick={this.closeModalInfo}
              >
                Cerrar
              </button>
              <button
                className="btn btn-primary mt-3 no-print" // Estilo del botón
                onClick={this.handlePrint} // Manejador de eventos
              >
                Imprimir ticket
              </button>
            </div>
          )
        }
        {
          this.state.modalInfoVisibleEditar && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                padding: "20px",
                width: "500px",

              }}
            >
              <h4 className="mb-4">Editar Información - {this.state.selectedItem?.nombre_cliente}</h4>

              <div>
                <h5 className="text-primary">General</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label>Expediente:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.orden_servicio_new}
                      onChange={(e) => this.handleChange("orden_servicio_new", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Estado:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.estado_nombre}
                      onChange={(e) => this.handleChange("estado_nombre", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Teléfono:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.numero_contacto}
                      onChange={(e) => this.handleChange("numero_contacto", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Fecha:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.selectedItem?.fecha}
                      onChange={(e) => this.handleChange("fecha", e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label>Hora:</label>

                    <TimePicker
                      name="horaInicial"
                      format="HH:mm" // Formato de 24 horas
                      value={this.parseTimeStringToDate(this.state.selectedItem?.hora_inicial)} // Valor actual
                      onChange={(time) => {
                        console.log(time); // Verifica qué se está pasando en el evento
                        this.handleChange("hora_inicial", time); // Pasa el valor directamente
                      }}
                      hideMinutes={(minute) => minute % 15 !== 0} // Mostrar solo minutos múltiplos de 15
                      editable={false} // No permitir edición manual
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Placa:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={this.state.selectedItem?.placa_vh}
                      onChange={(e) => this.handleChange("placa_vh", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Origen:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.direccion_inicial}
                      onChange={(e) => this.handleChange("direccion_inicial", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Destino:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.direccion_final}
                      onChange={(e) => this.handleChange("direccion_final", e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Observaciones:</label>
                    <textarea
                      className="form-control"
                      value={this.state.selectedItem?.observaciones}
                      onChange={(e) => this.handleChange("observaciones", e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <hr />

              <button
                className="btn btn-primary mt-3"
                onClick={() => this.guardarEdicion(this.state.selectedItem?.id)}
              >
                Actualizar Cambios
              </button>
              <button
                className="btn btn-secondary mt-3 ml-3"
                onClick={this.closeModalInfoEditar}
              >
                Cerrar
              </button>
            </div>
          )
        }

        {/* modal crear clinente */}
        <div className="modal fade" id="FormularioModalCreacionCliente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-lg"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Creacion Cliente </h4>
                <button id='miBoton' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro_crear_cliente">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <form onSubmit={this.guardarCliente}>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="tipoDocumento">Tipo Documento</label>
                                    <select class="form-control" id="tipoDocumento" aria-describedby="tipoDocumentoHelp">
                                      <option value="1">CC</option>
                                      <option value="2">CE</option>
                                      <option value="3">NIT</option>
                                    </select>
                                    <small id="tipoDocumentoHelp" class="form-text text-muted">Selecciona el tipo de documento.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="nombres">Nombres</label>
                                    <input type="text" class="form-control" id="nombres" aria-describedby="nombresHelp" />
                                    <small id="nombresHelp" class="form-text text-muted">Ingresa tu nombre.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="telefono">Telefono</label>
                                    <input type="tel" class="form-control" id="telefono" aria-describedby="telefonoHelp" />
                                    <small id="telefonoHelp" class="form-text text-muted">Ingresa tu número de teléfono.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="departamento">Departamento</label>
                                    {/* <input type="text" class="form-control" id="departamento" aria-describedby="departamentoHelp" /> */}
                                    <select id="departamento" onChange={this.handleDepartamentoChange} name="departamento" className="form-control form-control-sm">
                                      <option value={''}>Seleccione el departamento</option>
                                      {
                                        ListaDepartamentos.map((item, i) => (
                                          <option value={item.id} >{item.id} - {item.nombre}</option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="documento">Documento</label>
                                    <input type="text" class="form-control" id="documento" aria-describedby="documentoHelp" />
                                    <small id="documentoHelp" class="form-text text-muted">Ingresa tu número de documento.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" id="email" aria-describedby="emailHelp" />
                                    <small id="emailHelp" class="form-text text-muted">Ingresa tu dirección de correo electrónico.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="apellidos">Apellidos</label>
                                    <input type="text" class="form-control" id="apellidos" aria-describedby="apellidosHelp" />
                                    <small id="apellidosHelp" class="form-text text-muted">Ingresa tus apellidos.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="ciudad">Ciudad</label>
                                    <select id="ciudad" name="ciudad" className="form-control form-control-sm">
                                      <option value={''}>Seleccione el ciudad</option>
                                      {
                                        ListaCiudadesFiltradas.map((item, i) => (
                                          <option value={item.id} >{item.id} - {item.nombre}</option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="direccion">Direccion</label>
                                <input type="text" class="form-control" id="direccion" aria-describedby="direccionHelp" />
                                <small id="direccionHelp" class="form-text text-muted">Ingresa tu dirección.</small>
                              </div>
                              <button type="submit" class="btn btn-primary mt-4">Guardar</button>
                            </form>




                          </div>
                        </div>

                      </div>

                      <div className="modal-footer">
                        {/* <button onClick={this.exportToExcel} className="btn btn-primary">Guardar Cliente</button> */}
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div >


        <div className="modal fade" id="FormularioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-xl"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle del servicio <span id='servicio'></span></h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className='row g-3'>


                              <div className="col-md-12">
                                <label htmlFor="idprefactura">Id Prefactura </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm mt-2 mb-4"} type="text" name="idprefactura" id="idprefactura" aria-describedby="helpId" />

                              </div>



                            </div>

                            <table className="table table-hover table-condensed table-sm">

                              <thead className="thead-inverse  table-success ">
                                <tr>
                                  <th className="align-middle">ITEM</th>
                                  <th className="align-middle">PREFACTURA</th>
                                  <th className="align-middle">DOCUMENTO</th>
                                  <th className="align-middle">NOMBRE USUARIO</th>
                                  <th className="align-middle">CANT DE SERVICIO</th>
                                  <th className="align-middle">TOTAL</th>
                                  <th className="align-middle">ACCION</th>

                                </tr>
                              </thead>
                              <tbody>

                                {
                                  ListaPrefactura.map((item, i) => (
                                    <tr key={item.id}>
                                      <td>{i + 1}</td>
                                      <td>{item.id_prefactura}</td>
                                      <td>{item.documento}</td>
                                      <td>{item.nombre_cliente}</td>
                                      <td>{item.servicio}</td>
                                      <td>{item.total}</td>
                                      <td><button className='btn btn-primary btn-sm' onClick={() => this.GenerarSiigo(item.id_prefactura)}> Generar SIIGO </button></td>

                                    </tr>
                                  ))
                                }
                              </tbody>

                            </table>

                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-xl-12 text-center'>
                            <div className="btn-group" role="group" aria-label="">
                              <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Consultar Prefactura</button>&nbsp;&nbsp;


                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button onClick={this.exportToExcel} className="btn btn-primary">Descargar Excel</button>
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




      </div >
    );
  }
}

export default Listar;