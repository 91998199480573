import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { MENUITEMS } from './menu';
import { ArrowRight, ArrowLeft, Grid } from 'react-feather';
import { Link } from 'react-router-dom'
import configDB from '../../data/customizer/config';
import { DefaultLayout } from '../theme-customizer';
import { useTranslation } from 'react-i18next';
import { DollarSign, Table, Home, Eye, Activity, FolderPlus, Archive, Cloud, Folder, FileText, Server, BarChart, Users, Book, ShoppingBag, List, Mail, MessageCircle, Briefcase, Monitor, Navigation, Clock, CheckSquare, Calendar, Image, Film, Radio, Map, Edit, Package } from 'react-feather'
import authAxios from "../../services/Token";

const Sidebar = () => {
  const id = window.location.pathname.split('/').pop()
  const defaultLayout = Object.keys(DefaultLayout);
  const layout = id ? id : defaultLayout
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [cliente, setCliente] = useState(0);
  const [menuCollapsed, setMenuCollapsed] = useState(true);


  const [MenuTest, setMenuTest] = useState(10000);
  const [Activamenu, setActivamenu] = useState(10000);
  const [ListaMenu, setListaMenu] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [Rol, setRol] = useState('');
  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  // const [Administrador, setAdministrador] = useState(0);
  const [AdministradorMenu, setAdministradorMenu] = useState(0);

  const { t } = useTranslation();
  const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = useSelector(content => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (configDB.data.settings.sidebar.type.split(' ').pop() === 'material-type' || configDB.data.settings.sidebar.type.split(' ').pop() === 'advance-layout')
        document.querySelector(".sidebar-main").className = "sidebar-main hovered"
    } else {
      if (document.getElementById("sidebar-main"))
        document.querySelector(".sidebar-main").className = "sidebar-main"
    }

  }
  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none")
    setAdministradorMenu(localStorage.getItem('admin'))
    window.addEventListener('resize', handleResize)
    handleResize();
    CargarMenu();

    const currentUrl = window.location.pathname;
    MENUITEMS.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl)
          setNavActive(Items)
        if (!Items.children) return false
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl)
            setNavActive(subItems)
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems)
              return true
            }
            else {
              return false
            }
          })
          return subItems
        })
        return Items
      })
      return items
    })
    window.addEventListener('scroll', handleScroll)
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }

  }, [layout, mainmenu]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }



  const setNavActive = (item) => {
    MENUITEMS.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item) {
          Items.active = false
          document.querySelector(".bg-overlay1").classList.remove("active")
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true
          document.querySelector(".sidebar-link").classList.add("active")
        }
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            }
            else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = !item.active
    setMainMenu({ mainmenu: MENUITEMS })
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
      document.querySelector(".mega-menu-container").classList.remove("d-block")
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
      }
    }

    if (!item.active) {
      MENUITEMS.map(a => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item))
            Items.active = false
          if (!Items.children) return false
          Items.children.forEach(b => {
            if (Items.children.includes(item)) {
              b.active = false
            }
            if (!b.children) return false
            b.children.forEach(c => {
              if (b.children.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        });
        return a
      });
    }

    item.active = !item.active
    setMainMenu({ mainmenu: MENUITEMS })
  }

  const PasaruRL = (id, chi) => {
    toggletNavActive(chi);
    localStorage.setItem('urlid', id);

  }
  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570)
      } else {
        setMargin(-3464)
      }
      document.querySelector(".right-arrow").classList.add("d-none")
      document.querySelector(".left-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += (-width));
      document.querySelector(".left-arrow").classList.remove("d-none")
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      document.querySelector(".left-arrow").classList.add("d-none")
      document.querySelector(".right-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += width);
      document.querySelector(".right-arrow").classList.remove("d-none")
    }
  }

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active")
    document.querySelector(".sidebar-link").classList.remove("active")
  }

  const activeClass = (menuActiveClass) => {
    if (menuActiveClass === true) {
      document.querySelector(".sidebar-link").classList.add("active")
      document.querySelector(".bg-overlay1").classList.add("active")
    } else {
      document.querySelector(".sidebar-link").classList.remove("active")
      document.querySelector(".bg-overlay1").classList.remove("active")
    }

  }

  const openCloseSidebar = (toggle) => {
    if (!toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "

    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
    }
    setMenuCollapsed(!menuCollapsed);
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
  }

  const MenuPruebas = (act, menu) => {
    if (menu === Activamenu) {
      // Se coloca 10000 para inactivar el menú o colapsarlo
      setMenuTest(10000);
      setActivamenu(menu);
      // setMenuCollapsed(true); // Colapsa el menú cuando se cierra
    } else {
      // Abre el menú
      setMenuTest(menu);
      setActivamenu(menu);
      // setMenuCollapsed(false); // Expande el menú cuando se abre
    }
  };


  const CargarMenu = () => {
    var ide = localStorage.getItem("idusuario");
    authAxios.get("configuracion/menu/" + ide)
      .then((datosRespuesta) => {
        localStorage.setItem('rol', datosRespuesta.data.rol);
        setListaMenu(datosRespuesta.data.menu);
        setCliente(datosRespuesta.data.cliente);
        setRol(datosRespuesta.data.rol);
        // setAdministrador(datosRespuesta.data.rol);
      })
      .catch();

  }

  return (
    <Fragment>
      <div className={`bg-overlay1`} onClick={() => { closeOverlay() }} ></div>
      
      <div className="sidebar-wrapper" style={{ background: `${cliente.color_p}` }} id="sidebar-wrapper" >
        <div className="logo-wrapper" style={{ background: 'white', marginBottom: '17px' }}>
          <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}>
            <img className="img-fluid for-light" src={`https://app.transorientesas.com/storage/${cliente.imagen}`} alt="" />
            <img className="img-fluid for-dark" src={`https://app.transorientesas.com/storage/${cliente.imagen}`} alt="" />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i></div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebartoogle)}><Grid className="status_toggle middle sidebar-toggle" /></div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}><img className="img-fluid" src={require("../../assets/images/logo/logo-icon.png")} alt="" /></Link>
        </div>

        {!menuCollapsed && (
          <div style={{padding:'10px'}}>
            <input
              type="text"
              className='form-control'
              placeholder="Buscar..."
              value={searchText}
              onChange={handleSearchInputChange}
            />
          </div>
        )}


        <nav className="sidebar-main" id="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft /></div>
          <div id="sidebar-menu" style={wrapper.split(' ').includes('horizontal-wrapper') ? { 'marginLeft': margin + 'px' } : { margin: '0px' }}>
            <ul className="sidebar-links custom-scrollbar" >
              <li className="back-btn">
                <div className="mobile-back text-end" key={1}><span>{"Back"}</span><i className="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
              </li>
              {
                MENUITEMS.map((Item, i) =>
                  <Fragment key={i}>

                    {

                      Item.Items.map((menuItem, i) =>
                        <>
                          {AdministradorMenu == 'ADMINISTRADOR' ?
                            <li className="sidebar-list" key={i}>
                              {(menuItem.type === 'sub') ?

                                <a href="javascript" className={`sidebar-link sidebar-title ${menuItem.active ? 'active' : ''}`} onClick={(event) => { event.preventDefault(); setNavActive(menuItem); activeClass(menuItem.active) }}>
                                  <menuItem.icon style={{ color: 'white' }} />
                                  <span style={{ color: 'white' }}>{t(menuItem.title)}</span>
                                  {menuItem.badge ? <label className={menuItem.badge}> = {menuItem.badgetxt}</label> : ""}
                                  <div className="according-menu">
                                    {menuItem.active ?
                                      <i className="fa fa-angle-down" style={{ color: 'white' }}></i>
                                      : <i className="fa fa-angle-right" style={{ color: 'white' }}></i>
                                    }
                                  </div>
                                </a>
                                : ''}

                              {(menuItem.type === 'link') ?
                                <Link to={menuItem.path + '/' + layout} className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(menuItem)}>
                                  <menuItem.icon style={{ color: 'white' }} />
                                  <span style={{ color: 'white' }}>{t(menuItem.title)}</span>
                                  {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                                </Link>
                                : ''}

                              {menuItem.children ?

                                <ul className="sidebar-submenu"
                                  style={menuItem.active ? sidebartoogle ? { opacity: 1, transition: 'opacity 500ms ease-in' } : { display: "block" } : { display: "none" }}>

                                  {menuItem.children.map((childrenItem, index) => {

                                    return (
                                      <li key={index}>
                                        {(childrenItem.type === 'sub') ?
                                          <a href="javascript" className={`${childrenItem.active ? 'active' : ''}`} onClick={(event) => { event.preventDefault(); toggletNavActive(childrenItem) }}>*{t(childrenItem.title)}
                                            <span className="sub-arrow" style={{ color: 'white' }}>
                                              <i className="fa fa-chevron-right" style={{ color: 'white' }}></i>
                                            </span>
                                            <div className="according-menu">
                                              {childrenItem.active ?
                                                <i className="fa fa-angle-down" style={{ color: 'white' }}></i>
                                                : <i className="fa fa-angle-right" style={{ color: 'white' }}></i>
                                              }
                                            </div>
                                          </a>
                                          : ''}

                                        {(childrenItem.type === 'link') ?
                                          <Link to={childrenItem.path + '/' + layout} className={`${childrenItem.active ? 'active' : ''}`} onClick={() => PasaruRL(childrenItem.parametro, childrenItem)}>={t(childrenItem.title)}</Link>
                                          : ''}

                                        {childrenItem.children ?
                                          <ul className="nav-sub-childmenu submenu-content"
                                            style={childrenItem.active ? { display: "block" } : { display: "none" }}
                                          >
                                            {childrenItem.children.map((childrenSubItem, key) =>
                                              <li key={key}>
                                                {(childrenSubItem.type === 'link') ?
                                                  <Link to={childrenSubItem.path + '/' + layout} className={`${childrenSubItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(childrenSubItem)}>{t(childrenSubItem.title)}</Link>
                                                  : ''}
                                              </li>
                                            )}
                                          </ul>
                                          : ""}

                                      </li>
                                    )
                                  })}
                                </ul>
                                : ''}
                            </li>
                            : ''}

                        </>
                      )

                    }
                  </Fragment>
                )}
              {
                ListaMenu.map((Item, k) => {
                  // Filtra los subítems basados en el valor de searchText
                  const filteredChildren = Item.children.filter((sub) =>
                    sub.titulo.toLowerCase().includes(searchText.toLowerCase())
                  );

                  if (filteredChildren.length === 0) {
                    // Si no hay subítems después de aplicar el filtro, omite este elemento del menú.
                    return null;
                  }

                  return (
                    <Fragment key={k}>
                      <li className="sidebar-list">
                        <a href="javascript" style={{ color: 'white' }} className={`sidebar-link sidebar-title`} onClick={(event) => { event.preventDefault(); MenuPruebas(Activamenu, k) }}>

                          {(
                            () => {
                              switch (Item.icon) {
                                case 'Users': return (<Users style={{ color: 'white' }}></Users>);
                                case 'Folder': return (<Folder style={{ color: 'white' }}></Folder>);
                                case 'Home': return (<Home style={{ color: 'white' }}></Home>);
                                case 'FolderPlus': return (<FolderPlus style={{ color: 'white' }}></FolderPlus>);
                                case 'Cloud': return (<Cloud style={{ color: 'white' }}></Cloud>);
                                case 'FileText': return (<FileText style={{ color: 'white' }}></FileText>);
                                case 'Server': return (<Server style={{ color: 'white' }}></Server>);
                                case 'Users': return (<Users style={{ color: 'white' }}></Users>);
                                case 'ShoppingBag': return (<ShoppingBag style={{ color: 'white' }}></ShoppingBag>);
                                case 'List': return (<List style={{ color: 'white' }}></List>);
                                case 'Mail': return (<Mail style={{ color: 'white' }}></Mail>);
                                case 'MessageCircle': return (<MessageCircle style={{ color: 'white' }}></MessageCircle>);
                                case 'Monitor': return (<Monitor style={{ color: 'white' }}></Monitor>);
                                case 'Clock': return (<Clock style={{ color: 'white' }}></Clock>);
                                case 'Calendar': return (<Calendar style={{ color: 'white' }}></Calendar>);
                                case 'CheckSquare': return (<CheckSquare style={{ color: 'white' }}></CheckSquare>);
                                case 'Image': return (<Image style={{ color: 'white' }}></Image>);
                                case 'Film': return (<Film style={{ color: 'white' }}></Film>);
                                case 'Radio': return (<Radio style={{ color: 'white' }}></Radio>);
                                case 'Map': return (<Map style={{ color: 'white' }}></Map>);
                                case 'Edit': return (<Edit style={{ color: 'white' }}></Edit>);
                                case 'Package': return (<Package style={{ color: 'white' }}></Package>);
                                case 'Eye': return (<Eye style={{ color: 'white' }}></Eye>);
                                case 'Activity': return (<Activity style={{ color: 'white' }}></Activity>);
                                case 'Archive': return (<Archive style={{ color: 'white' }}></Archive>);
                                case 'BarChart': return (<BarChart style={{ color: 'white' }}></BarChart>);
                                case 'Book': return (<Book style={{ color: 'white' }}></Book>);
                                case 'Briefcase': return (<Briefcase style={{ color: 'white' }}></Briefcase>);
                                case 'Navigation': return (<Navigation style={{ color: 'white' }}></Navigation>);
                                case 'Table': return (<Table style={{ color: 'white' }}></Table>);
                                case 'DollarSign': return (<DollarSign style={{ color: 'white' }}></DollarSign>);

                              }
                            }

                          )()}
                          <span style={{ color: 'white' }}>{Item.title}</span>
                          <div className="according-menu">
                            <i className="fa fa-angle-right" style={{ color: 'white' }}></i>
                          </div>
                        </a>
                        <ul
                          className="sidebar-submenu"
                          style={MenuTest == k ? { display: "block", color: 'white', textTransform: 'uppercase' } : { display: "none", color: 'white' }}
                        >
                          {filteredChildren.map((sub, j) => (
                            <li key={j}>
                              {sub.url == '0' ? (
                                <Link
                                  to={'pages/' + sub.id + '/' + layout}
                                  className={''}
                                  onClick={() => PasaruRL(sub.parametro, sub)}
                                  style={{ color: 'white' }}
                                >
                                  {t(sub.titulo)}
                                </Link>
                              ) : (
                                <Link
                                  to={sub.url + '/' + layout}
                                  className={''}
                                  onClick={() => PasaruRL(sub.parametro, sub)}
                                  style={{ color: 'white' }}
                                >
                                  {t(sub.titulo)}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </Fragment>
                  );
                })
              }
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div>
        </nav>
      </div>
    </Fragment>
  );
}

export default Sidebar;