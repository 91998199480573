import React, { Fragment } from 'react';
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from 'react-toastify'
import { Outlet } from 'react-router-dom';
import authAxios from "../services/Token";


const AppLayout = ({ children, classNames, ...rest }) => {
  var rol = localStorage.getItem('rol');

  const fetchData = async () => {

    // if (rol === null) {
      try {
        const ide = localStorage.getItem('idusuario');
        const response = await authAxios.get('configuracion/menu/' + ide);
        console.log(response.data.cliente.color_s);
        localStorage.setItem('rol', response.data.rol);
        localStorage.setItem('color_secundario', response.data.cliente.color_s);
        rol = response.data.rol;
      } catch (error) {
        
      }
    // }
  };

  fetchData();



  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeCustomize rol={rol} />
      <ToastContainer />
    </Fragment>
  );
}

export default AppLayout;